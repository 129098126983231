// import React, { useEffect, useState } from 'react';
// import { useStripe } from '@stripe/react-stripe-js';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router';
// import { paymentSucceded } from '../../redux/action/subscriptionAction';
// import { toast } from 'react-toastify';

// const GPayCheckoutForm = ({ clientSecret, subscription }) => {
//   const stripe = useStripe();
//   const [paymentRequest, setPaymentRequest] = useState(null);
//   const dispatch  = useDispatch()
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (stripe) {
//       const pr = stripe.paymentRequest({
//         country: 'US',  // Update as needed
//         currency: 'usd',
//         total: {
//           label: 'Total',
//           amount: subscription.price * 100,  // Example: $50.00
//         },
//         requestPayerName: true,
//         requestPayerEmail: true,
//       });

//       pr.canMakePayment().then((result) => {
//         if (result) {
//           setPaymentRequest(pr);
//         }
//       });
//     }
//   }, [stripe]);

//   const handlePaymentRequestButtonClick = async () => {
//     if (paymentRequest) {
//       paymentRequest.on('paymentmethod', async (event) => {

//         const { error, paymentIntent } = await stripe.confirmCardPayment(
//           clientSecret,
//           { payment_method: event.paymentMethod.id }
//         );

//         if (error) {
//           event.complete('fail');
//           console.error(error.message);
//         } else {
//           event.complete('success');
//           dispatch(paymentSucceded(subscription._id, clientSecret, paymentIntent.id));
//           toast.success('Payment Successful!')
//          navigate('/')
//         }
//       });
//     }
//   };

//   return (
//     <div>
//       <h2>Checkout with Google Pay</h2>
//       {paymentRequest && (
//         <button onClick={handlePaymentRequestButtonClick}>
//           Pay with Google Pay
//         </button>
//       )}
//     </div>
//   );
// };

// export default GPayCheckoutForm;

import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";

export default function GooglePayCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const subscription = useSelector(
    (state) => state?.subscriptions?.subscription
  );
  console.log('subscription12',subscription);

  useEffect(() => {
    if (!stripe || !subscription) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Subscription Payment",
        amount: subscription?.price * 100, // Stripe expects the amount in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
      supportedPaymentMethods: [
        {
          supportedMethods: "google_pay",
          data: {
            merchantName: "Iq Hire",
            merchantId: "", 
          },
        },
      ],
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setCanMakePayment(true);
      }
      else{
        setCanMakePayment(false);
      }
    });
  }, [stripe, subscription]);

  const handlePayment = (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    paymentRequest.show();
  };

  return (
    <Box>
      <Typography variant="h3" className="fs-22 fw-600">
        Google Pay
      </Typography>
      {canMakePayment && paymentRequest ? (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: "default",
                theme: "light",
                height: "64px",
                text: "pay", // Customize the text
              },
            },
          }}
        />
      ) : (
        <Typography>Google Pay is not available on this device/browser.</Typography>
      )}
    </Box>
  );
}
