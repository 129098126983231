import { Box, Button, Dialog, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ColorizeIcon from '@mui/icons-material/Colorize';
import CancelIcon from '@mui/icons-material/Cancel';
import { MuiColorInput } from 'mui-color-input';
import Glider from 'glider-js';
import 'glider-js/glider.min.css';
import "../job/job.scss";
import PlaceIcon from '@mui/icons-material/Place';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getSocialMediaTemplate } from '../../redux/action/chatbotAction';


const ShareSocialMediaTemplate = ({open,onClose,uploadedImage,logo}) => {
        const [loading, setLoading] = useState(true);
        const [activeIndex, setActiveIndex] = useState(0);
        const gliderRef = useRef(null); 
        const [templates, setTemplates] = useState([]);
        const navigate = useNavigate();
        const dispatch = useDispatch()
        console.log(gliderRef.current);
        const {socialMediaText,chatbotLoading}=useSelector((state)=>state?.jobDescriptionFileUploadReducer)
        const params = useParams();
        useEffect(() => {
        if(gliderRef.current){

            setTimeout(()=>{
                new Glider(gliderRef.current, {
                    slidesToShow: 1,
                    scrollLock: true,
                    draggable: true,
                    dots: '#dots',
                    arrows: {
                      prev: '.glider-prev',
                      next: '.glider-next'
                    }
                  });
                   // Event listener to capture the active index when a slide becomes visible
    gliderRef.current.addEventListener('glider-slide-visible', function (event) {
      setActiveIndex(event.detail.slide); 
    });
    setLoading(!loading);
            },1000)

           
        }
             
          
        }, []);

        useEffect(() => {
            if(params?.id){
              dispatch(getSocialMediaTemplate(params?.id,setTemplates))
            }
           
        }, []);






  return (

        (templates.length>0) &&
        <Box className='startpopup'>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{background:"#FFE9D9"}}>
                    <Box>
                        <Box className='px-30'>
                            <Box className='mx-auto mt-30 mb-30' sx={{maxWidth:"440px"}}>
                                <Box class="glider-contain">
                                    <Box class="glider bg-white" ref={gliderRef}>

                                        {templates.map((template,index)=>(
                                            <Box>
                                            <Box className='p-30'>
                                                <Typography variant='h4' className='fs-8 fw-500 text-color-c2 mb-20'>{template.description}</Typography>

                                            </Box>
                                            <Box className='glider-img-box '>

                                                <img className='main-img' src={template.image} />
                                            </Box>

                                        </Box>
                                        ))}
                                    </Box>
                                    <Box className='glider-btn-box'>
                                        <button aria-label="Previous" class="glider-prev"><KeyboardArrowLeftIcon/></button>
                                        <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                        <button aria-label="Next" class="glider-next"><KeyboardArrowRightIcon/></button>
                                    </Box>
                                    <Box className='glider-btn-box number'>
                                        <button aria-label="Previous" class="glider-prev">1</button>
                                        <Divider className='mb-20'sx={{border:"2px solid #DEDEDE !important", borderColor:"#DEDEDE !important"}}></Divider>
                                        <button aria-label="Next" class="glider-next">2</button>
                                    </Box>
                                    <Box role="tablist" id="dots" class="dots"></Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    
  )
}

export default ShareSocialMediaTemplate;
