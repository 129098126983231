import React, { useState, useEffect, useRef } from "react";
import "./Chatbot.scss";
import { competencyList, findMatchingAnswer, IsOptionMatches, names, selectedOptionsValue } from "./helper";
import { Box, Button, IconButton, MenuItem, Select } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Tooltip from "@mui/material/Tooltip";
import gallary from "../../assets/img/svg/gallary.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';

const Chatbot = ({
  sortedPdfAnswers,
  updatedAnswers,
  handleContinue,
  lastQuestion,
  questions,
  onSendMessage,
  botLoadingMessage,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  currentQuestion,
  messages,
  handleBack,
  setMessages,
  inputType,
  goingBack,
  setGoingBack,
  pdfAnswers
}) => {
  const [message, setMessage] = useState("");
  const sendBtn = useRef(null);
  const chatEndRef = useRef(null);
  const [file, setFile] = useState(null);
  const [botTyping, setBotTyping] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [multiMessage, setMultiMessage] = useState("");
  const [isOptionPresent, setIsOptionPresent] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies]= useState([]);
  const fileUploadRef = useRef(null);
  const isUserAuthenticated = localStorage?.getItem("accessToken");
  const userData = useSelector(
    (state) => state?.authenticationReducer?.data?.user
  );
  const[isFileUploaded, setIsFileUploaded]=useState(false);
  const theme = useTheme();
  const today = new Date().toISOString().split('T')[0];   
  let matchingoptions;
  useEffect(() => {
    
    console.log('isFileUploaded12',pdfAnswers);
    if(Object?.keys(pdfAnswers).length>0 && currentQuestionIndex>4){
   
      const answer= findMatchingAnswer(currentQuestion?.key,pdfAnswers);
        if (currentQuestion?.inputType === "text") 
      {
          setMessage(answer);
      } 
      else if (currentQuestion?.inputType === "select") {
             matchingoptions = IsOptionMatches(
              currentQuestion,
              pdfAnswers
            );
            console?.log('matchingoptions',matchingoptions)
            if (matchingoptions.IsOptionMatches) {
              setIsOptionPresent(true);
            } else {
              setIsOptionPresent(false);
            }
            
          } 

            else {
             
              if (currentQuestionIndex === 16) {
                // setMessage(competencyList.get(competencies[0]));
                const answers = questions[15]["A16"]?.split(",");
                const temp = (names.find((ele) => ele.optionName === answers[0])?.optionPrefilledValue) || "";
                console.log(temp);
                if(temp!=""){
                  setMessage(temp);
                  return;
                }
        
                setMessage(selectedOptionsValue(selectedCompetencies[0]));
              }
              if (currentQuestionIndex === 17) {
                setMessage(selectedOptionsValue(selectedCompetencies[1]));
              }
              if (currentQuestionIndex === 18) {
                setMessage(selectedOptionsValue(selectedCompetencies[2]));
              }
               matchingoptions = IsOptionMatches(
                currentQuestion,
                pdfAnswers
              );
              if (matchingoptions.IsOptionMatches) {
                setIsOptionPresent(true);
              } else {
                setIsOptionPresent(false);
              }
        
      }
    } 
    
    else {
  
      if (currentQuestionIndex === 16) {
        // setMessage(competencyList.get(competencies[0]));

        setMessage(selectedOptionsValue(selectedCompetencies[0]));
      }
      if (currentQuestionIndex === 17) {
        setMessage(selectedOptionsValue(selectedCompetencies[1]));
      }
      if (currentQuestionIndex === 18) {
        setMessage(selectedOptionsValue(selectedCompetencies[2]));
      }
     
    }
  }, [pdfAnswers, currentQuestion, currentQuestionIndex]);

  console.log('isOptionPresent55',isOptionPresent);
  useEffect(()=>{
    if (!isOptionPresent && (currentQuestion?.inputType === "multi-select" || currentQuestion?.inputType === "select")) {
    setMessage("Custom...");
    setMultiMessage(findMatchingAnswer(currentQuestion?.key,pdfAnswers));
    }
    else if((isOptionPresent && (currentQuestion?.inputType === "multi-select" || currentQuestion?.inputType === "select")))
      {
        console?.log("matchingoptions",matchingoptions?.matchingOptions)
      setMessage(matchingoptions?.matchingOptions)
    }
  },[isOptionPresent,currentQuestion?.key])
  useEffect(() => {
    setTimeout(()=>{
      scrollToBottom()
      if(goingBack) {
        if(currentQuestion.inputType==="select") {
          if(currentQuestion[`A${currentQuestion?.id}`]!="") {
            setMultiMessage(currentQuestion[`A${currentQuestion?.id}`]);
            setMessage(['Custom...'])
            
            return; 
          }
        }
        setMessage(currentQuestion[`A${currentQuestion?.id}`]);
        setGoingBack(false)
      } else {
        if(currentQuestion.inputType==="select") {
          if(currentQuestion.options?.includes(currentQuestion[`A${currentQuestion?.id}`])){
            setMessage(currentQuestion[`A${currentQuestion?.id}`]);
            return
          } else if(currentQuestion[`A${currentQuestion?.id}`]!="") {
            setMultiMessage(currentQuestion[`A${currentQuestion?.id}`]);
            setMessage(['Custom...'])
            
            return; 
          }
        } else if(currentQuestion[`A${currentQuestion?.id}`]!="") {
          setMessage(currentQuestion[`A${currentQuestion?.id}`]);
        }
         
      }
    },300)
   
  },[currentQuestion, currentQuestionIndex])

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleMessageExport = async (message, file) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 1, text: message, sender: "user" },
    ]);
    await onSendMessage(file, message);
    scrollToBottom();
  };

  const handleSkip=async ()=>{
   await handleMessageExport(message, file);
  }
  const handleSendMessage = async () => {
    console?.log('pdfAnswers600',Object?.keys(pdfAnswers)?.length===0)
    setProcessing(true);
    if (message?.trim() || file) {
      setBotTyping(true);
      await handleMessageExport(message, file);
      console?.log('pdfAnswers600',Object?.keys(pdfAnswers)?.length)
      if (Object?.keys(pdfAnswers)?.length===0) {
        if (!(currentQuestionIndex > 15 && currentQuestionIndex < 18)) {
          setMessage("");
        }
        setMultiMessage("");
        setFile(null);
      } else {
        if (currentQuestionIndex === 17) {
          setCompetencies("");
        }
      }
    }
    setProcessing(false);
    setIsOptionPresent(false);
  };
  const handleOk = async () => {
    setProcessing(true);
    setBotTyping(true);
    await handleMessageExport("Ok", file);

    setProcessing(false);
  };
  const handleTextMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSelectChange = async (option) => {
    if (option?.includes("Custom...")) {
      currentQuestion[`A${currentQuestionIndex+1}`]=""
      inputType = "multi-text";
      setMessage(["Custom..."]);
      return;
    }
    if (processing) return;
    setProcessing(true);
    if (option?.trim() || file) {
      setBotTyping(true);
      await handleMessageExport(option, file);
      if (Object?.keys(pdfAnswers)?.length===0) {
        setMessage("");
        setMultiMessage("");
        setFile(null);
      }
    }
    setProcessing(false);
  };
  const handleMultiSelectChange = (option) => {
    console.log('option25',message);
    if (message?.includes(option)) {
      const tempArr = message?.split(", ");
      const index = tempArr?.indexOf(option);
      if (index > -1) {
        tempArr?.splice(index, 1);
      }
      setMessage(tempArr.join(", "));
      return;
    }
    
    else if (message && currentQuestion?.key === "opportunities") {
      console.log("message", option);
      const mesarray = [];
      mesarray.push(option);
      const tempMessage = message + ", " + option;
      console.log('options90',tempMessage);
      const options = tempMessage.split(", ");
      console.log('options12',options);
      // const isCustomSelected = options.filter((ele) => ele === "Custom...");
      // if (isCustomSelected.length !== 1) {
        setMessage(tempMessage);
      // }
    } else {
      setMessage(option);
    }
  };

 
  const handleChange = (event) => {
    // if(message.length > 3){
    //   return;
    // } 

    const { target: { value } } = event;
    if(value.length > 3){
      return;
    }
  
    setSelectedCompetencies(
      typeof value === 'string' ? value.split(',') : value
    );
  
    // Assuming 'message' is used to track the selected competencies as a string
    const tempMessage = value.join(", ");
    setMessage(tempMessage);
    
    
    console.log('selectedCompetencies:', value);
    console.log('message:', tempMessage);
  };
  
  console.log("processing",processing);
  console.log('selectedCompetencies',selectedCompetencies);


function getStyles(name, personName, theme) {
    const isSelected = personName.includes(name);
  
    return {
      fontWeight: isSelected
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
      
     
      backgroundColor: isSelected
        ? "#F89B53" 
        : theme.palette.background.paper,
  
      color: isSelected
        ? theme.palette.primary.contrastText 
        : theme.palette.text.primary,
    };
  }

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  const handleFileChange = async (e) => {
    
    if (processing) return;
    const selectedFile = e.target.files[0];
    setFile(e.target.files[0]);
    setMessage(e.target.files[0].name);
    setProcessing(true);
    if (message?.trim() || selectedFile) {
      setBotTyping(true);
      await handleMessageExport(e.target.files[0].name, selectedFile);
      setIsFileUploaded(true);
      setMessage("");
      setFile(null);
    }
    
    setProcessing(false);
  };

  const handleMultiTextMessage = (e) => {
    setMultiMessage(e.target.value);
  };

  const handleCustomSelect = async (e) => {
    if (inputType === "select") {
      
      setProcessing(true);
      if (multiMessage?.trim() || file) {
        setBotTyping(true);
        await handleMessageExport(multiMessage, file);
        console?.log('pdfAnswers333',pdfAnswers)
        if (Object?.keys(pdfAnswers).length===0) {
          setFile(null);
          setMultiMessage("");
          setMessage("");
        }
      }
      setProcessing(false);
    } 
    else if (inputType === "multi-select" && currentQuestion?.key!=="Top_Competencies") {
      setProcessing(true);
      const tempMessages = message?.split(", ");
      const index = tempMessages?.findIndex((item) => item === "Custom...");
      if (tempMessages) {
        tempMessages[index] = multiMessage;
        const options = tempMessages.join(", ");
        setBotTyping(true);
        await handleMessageExport(options, file);
      }
      setFile(null);
      setMultiMessage("");
      setProcessing(false);
    }

    else if (inputType === "multi-select" && currentQuestion?.key==="Top_Competencies") {
      setProcessing(true);
      const tempMessages = message?.split(", ");
      const index = tempMessages?.findIndex((item) => item === "Custom...");
      if (tempMessages) {
        tempMessages[index] = multiMessage;
        const options = tempMessages.join(", ");
        setBotTyping(true);
        await handleMessageExport(options, file);
      }
      setFile(null);
      setMultiMessage("");
      setProcessing(false);
    }
  };

  console.log("currentQuestion", currentQuestion);
  return (
    <Box className="chatbot">
      <Box className="messages">
        <p style={{ fontSize: "20px" }}>
          {`Welcome back, ${userData?.fullName}! Let's start creating a new job vacancy`}
        </p>
        {messages?.map((msg) => (
          <Box
            key={msg.id}
            className={`message ${msg.sender}-message`}
            ref={chatEndRef}
          >
            <Box className="message-content">
              <Box className="message-header">
                <img
                  src={`https://cdn-icons-png.flaticon.com/512/21/21104.png`}
                  alt={msg.sender}
                  className="message-avatar"
                />
                <span className="message-username">
                  {msg.sender === "user"
                    ? `${
                        isUserAuthenticated === null
                          ? "Guest"
                          : userData?.fullName
                      }`
                    : "Virtual Assistant"}
                </span>
              </Box>
             
              <p className="message-text" ref={chatEndRef}>
                {msg?.text}
              </p>
             
              {messages.length== messages[messages.length-1].id && <>
              {currentQuestion.inputType === "select" &&
                msg.sender === "bot" &&
                msg.text === 
                  currentQuestion["Q" + (currentQuestionIndex + 1)] && (
                  <Box>
                    {currentQuestion.options.map((option, index) => {
                      return (
                        <Box
                          key={`option-${index}`}
                          onClick={() => handleSelectChange(option)}
                          className={`select-option ${
                            (message===option ||message?.includes(option) || currentQuestion[`A${currentQuestionIndex+1}`] === option) ? "selected" : ""
                          }`}
                        >
                          {option}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              {currentQuestion.inputType === "multi-select" &&
                msg.sender === "bot" && currentQuestion?.key === "opportunities" &&
                msg.text ===
                  currentQuestion["Q" + (currentQuestionIndex + 1)] && (
                  <Box>
                    {currentQuestion.options.map((option, index) => (
                      <Box
                        key={`option-${index}`}
                        onClick={() => handleMultiSelectChange(option)}
                        className={`select-option ${
                          message?.includes(option) ? "selected" : ""
                        }`}
                      >
                        {option}
                      </Box>
                    ))}
                  </Box>
                )}

              {currentQuestion.inputType==="date" && msg.sender === "bot" && msg.text ===
                  currentQuestion["Q" + (currentQuestionIndex + 1)] &&
               <Box>
               
                 <Box
                   key={`option-${1}`}
                   onClick={() => handleSelectChange(currentQuestion.options[0])}
                   className={`select-option ${
                     message?.includes(currentQuestion.options[0]) ? "selected" : ""
                   }`}
                   sx={{width:'50%'}}
                 >
                   {currentQuestion.options[0]}
                 </Box>
                 <input type="date"
                 style={{width:'50%'}}
                 onChange={(e) => handleSelectChange(e.target.value)}
                 className={`select-option `}
                 value={currentQuestion["A"+currentQuestion.id]}
                 min={today}
                 />
               
             </Box>
              }</>}
                {currentQuestion.inputType === "multi-select" &&
                  msg.sender === "bot" &&
                  currentQuestion?.key === "Top_Competencies" &&
                  msg.text ===
                    currentQuestion["Q" + (currentQuestionIndex + 1)] && (
                    <Box sx={{margin:"10px 0px 0px 50px"}}>
                      <Select
                      className="chatbot-dropdown"
            sx={{ width: "100%",border: "1px solid #F89B53" }}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedCompetencies}
            onChange={handleChange}
            // input={<OutlinedInput label="Name" />}
    //         renderValue={(selected) => {
    //   if (selected.length === 0) {
    //     return <em>Select Competencies</em>; // Placeholder text
    //   }
    //   return selected.join(', ');
    // }}
            displayEmpty
          renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>{names[0].optionName}</em>;
                }
                return selected.join(', ');
              }}
            MenuProps={MenuProps}
          >
          
            <MenuItem disabled value="">
            <span>{names[0].optionName}</span>
              </MenuItem>

              {/* Other options */}
              {names.slice(1).map((name, index) => (
                <MenuItem
                  key={index}
                  value={name.optionName}
                  style={getStyles(name.optionName, selectedCompetencies, theme)}
                >
                  {name.optionName}
                </MenuItem>
              ))}
          </Select>
                    </Box>
                  )}
              </Box>
            </Box>
          ))}

        {currentQuestionIndex === 0 && (
          <div className="message user-message">
            <div className="message-content">
              <Button
                className="continue-button primary-btn primary-btn-outline"
                onClick={() => {
                  handleOk();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
        {currentQuestionIndex>1 &&
        <button
                className="continue-button secondary-btn"
                onClick={() => {
                  handleBack();
                }}
              >
                Go to Previous Question
              </button>}
              {
                currentQuestion?.key==="slogan" &&
                <button
                style={{marginLeft:"25px"}}
                className="continue-button secondary-btn"
                onClick={() => {
                 handleSkip();
                }}
              >
                Skip
              </button>}
              
        {lastQuestion && (
          <div className="message user-message">
            <div className="message-content">
              <button
                className="continue-button primary-btn primary-btn-outline"
                onClick={() => {
                  handleContinue();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </Box>
      <Box className="input-container">
        <Box className="file-input-container">
          <input
            ref={fileUploadRef}
            type="file"
            title=""
            style={{ zIndex: 1 }}
            onChange={(e) => {
              handleFileChange(e);
            }}
            className="file-input"
            disabled={inputType === "file" ? false : true}
          />
          {currentQuestionIndex === 4 ? (
            <Tooltip title="Please click on it to upload a file" arrow>
              <IconButton
                sx={{ margin: 0, padding: 0, zIndex: 2 }}
                onClick={() => fileUploadRef.current?.click()}
              >
                <PhotoLibraryIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <PhotoLibraryIcon />
          )}
        </Box>
        {(inputType === "select" || inputType === "multi-select") &&
        message?.includes("Custom...") ? (
          <textarea
            type="text"
            value={multiMessage}
            onChange={handleMultiTextMessage}
            placeholder="Type your message..."
            className="message-input"
          />
        ) : (
          <textarea
            type="text"
            value={inputType === "text" ? message : ""}
            onChange={(e) => {
              handleTextMessage(e);
            }}
            placeholder="Type your message..."
            className="message-input"
            disabled={
              inputType === "text" &&
              !processing &&
              currentQuestionIndex !== 0 &&
              !lastQuestion
                ? false
                : true
            }
          />
        )}
        <Button
          ref={sendBtn}
          onClick={() => {
            inputType === "select" || inputType === "multi-select"
              ? handleCustomSelect()
              : handleSendMessage();
          }}
          disabled={processing || lastQuestion || currentQuestion?.id === 34}
          className="send-button p-0 text-orange bg-none"
          sx={{ minWidth: "Fit-content" }}
        >
          <SendOutlinedIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Chatbot;
