import Utils from "../../utils/reduxUtils";

const {
    GET_DASHBOARD_DATA,
    RESET_DASHBOARD_DATA
} = Utils.ActionName;

const initialState = {};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        case RESET_DASHBOARD_DATA:
            return { ...initialState };
        default:
            return state;
    }
};