//List Of Chatbot Questions
export const questionsList = [
    {
      id: 1,
      Q1: "Welcome back! Let's start creating a new job vacancy",
      A1: "",
      inputType: "text",
      key: "Greet",
    },
    {
      id: 2,
      // Q2: `Could you please provide your company website URL? This will help us extract the tone
      //      of voice from your company's online presence and will also help us to get information regarding about us section. Additionally, after providing the URL, you'll
      //      be given the option to make the job vacancy anonymous`,
      Q2:`Could you please provide your company's About Us URL? This will help us extract the tone
           of voice from your company's online presence and will also help us to get information regarding about us section. Additionally, after providing the URL, you'll
           be given the option to make the job vacancy anonymous`,
      A2: "",
      inputType: "text",
      key: "website_url",
    },
    {
      id: 3,
      Q3: "would you like this job vacancy to be posted anonymously?",
      A3: "",
      inputType: "select",
      options: [
        "Yes, I prefer to keep the job vacancy anonymous",
        "No, it's okay to disclose the company's identity.",
      ],
      key: "job_vacancy",
    },
    {
      id: 4,
      Q4: "Do you have a job description available?",
      A4: "",
      inputType: "select",
      options: [
        "Yes, Please upload or paste the job description",
        "No, Do not worry, we are here to help you craft one",
      ],
      key: "job_description",
    },
    {
      id: 5,
      Q5: "Please upload the job description file.",
      A5: "",
      inputType: "file",
      key: "upload_file",
    },
    {
      id: 6,
      Q6: "What will be the job title?",
      A6: "",
      inputType: "text",
      key: "Job_Title",
      isUploadedQuestion:"Yes"
      
    },
    {
      id: 7,
      Q7: "Could you provide a brief overview of the role's purpose? Example: Answer to this question will help us to generate detailed description of the role overview which is required in one of the sub-sections within the vacancy text",
      A7: "",
      inputType: "text",
      key: "Role_Overview",
      isUploadedQuestion:"Yes"
    },
    {
      id: 8,
      Q8: "What is the job level required for this vacancy?",
      A8: "",
      inputType: "select",
      options: [
        "Internship",
        "Starting professional",
        "Mid-career professional",
        "Senior professional",
        "C-level (CEO, CFO, etc.)",
        "Custom...",
      ],
      key: "Job_level",
      isUploadedQuestion:"Yes"
    },
    {
      id: 9,
      Q9: "What tasks and duties will the employee need to perform regularly?",
      A9: "",
      inputType: "text",
      // options: [
      //   "Managing projects",
      //   "Client interaction",
      //   "Data analysis",
      //   "Team collaboration",
      //   "Custom...",
      // ],
      key: "Tasks_and_Duties",
      isUploadedQuestion:"Yes"
    },
    {
      id: 10,
      Q10: `What specific experience are you looking for in a candidate for this role (e.g., managerial experience,
recruitment background, etc.)?`,
      A10: "",
      inputType: "text",
      // options: [
      //   "Project management",
      //   "Sales experience",
      //   "Technical expertise",
      //   "Customer service",
      //   "Custom...",
      // ],
      key: "Relevant_Experience",
      isUploadedQuestion:"Yes"
    },
    {
      id: 11,
      Q11: "What is the total years of experience required for this role?",
      A11: "",
      inputType: "select",
      options: ["No experience necessary","0 to 5 years", "5 to 10 years", "More than 10 years",
        "Custom..."],
      key: "Years_of_Experience_Required",
      isUploadedQuestion:"Yes"
    },
    {
      id: 12,
      Q12: "What is the minimum education background required, and which field?",
      A12: "",
      inputType: "select",
      options: [
        "High school diploma",
        "Professional education",
        "Associate degree",
        "Bachelor's degree",
        "Master's degree",
        "PhD",
        "Custom..."
      ],
      key: "Min_education_background",
      isUploadedQuestion:"Yes"
    },
    {
      id: 13,
      Q13: "What are the fields of study?",
      A13: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Options_for_field_of_study",
      isUploadedQuestion:"Yes"
    },
    {
      id: 14,
      Q14: "What specific skills are required for the job?",
      A14: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Skills",
      isUploadedQuestion:"Yes"
    },
    {
      id: 15,
      Q15: "What specific certifications are required for the job?",
      A15: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Skills_and_Certifications",
      isUploadedQuestion:"Yes"
    },
    {
      id: 16,
      Q16: "What are the top 3 competencies for this position?",
      A16: "",
      inputType: "multi-select",
      maxSelects: 3,
      options: [
        "Leadership",
        "Communication",
        "Problem-solving",
        "Teamwork",
        "Innovation",
        "Custom...",
      ],
      key: "Top_Competencies",
      isUploadedQuestion:"Yes"
    },
    {
      id: 17,
      Q17: `What does first competency look like within your company?`,
      A17: "",
      inputType: "text",
      key: "Competency_Details1",
      isUploadedQuestion:"Yes"
    },
    {
      id: 18,
      Q18: "What does Second Compentency look like within your company?",
      A18: "",
      inputType: "text",
      key: "Competency_Details2",
      isUploadedQuestion:"Yes"
    },
    {
      id: 19,
      Q19: "What does Third Compentency look like within your company?",
      A19: "",
      inputType: "text",
      key: "Competency_Details3",
      isUploadedQuestion:"Yes"
    },
    // {
    //   id: 20,
    //   Q20: "Who will the employee report to, and will they work independently or as part of a team?",
    //   A20: "",
    //   inputType: "select",
    //   options: [
    //     "Report to Manager",
    //     "Report to Team Lead",
    //     "Work independently",
    //     "Work as part of a team",
    //     "Custom...",
    //   ],
    //   key: "Reporting_Structure",
    // },
    {
      id: 20,
      Q20: "What are the main goals for this position?",
      A20: "",
      inputType: "text",
      // options: ["Custom..."],
      key: "Goals_Evaluations",
      isUploadedQuestion:"Yes"
    },
    {
      id: 21,
      Q21: "Can you describe the company culture and any specific values important for this role?",
      A21: "",
      inputType: "text",
      key: "company_highlights",
      isUploadedQuestion:"Yes"
    },
    {
      id: 22,
      Q22: "Are there opportunities for career advancement or professional development?",
      A22: "",
      inputType: "multi-select",
      options: [
        "Promotion opportunities",
        "Training programs",
        "Mentorship programs",
        "Continuing education opportunities",
        "Job rotation programs",
        "Leadership development programs",
        "Certification reimbursement",
        "Networking opportunities",
        "Cross-functional team projects",
        "Custom...",
      ],
      key: "opportunities",
      isUploadedQuestion:"Yes"
    },
    {
      id: 23,
      Q23: "Will this job be office-based, remote, or a mix of both??",
      A23: "",
      inputType: "select",
      options: ["Office-based", "Remote", "Hybrid",
        "Custom..."],
      key: "Work_environment",
      isUploadedQuestion:"Yes"
    },
    {
      id: 24,
      Q24: "Where is the job offering based?",
      A24: "",
      inputType: "text",
      key: "Job_Location",
      isUploadedQuestion:"Yes"
    },
    {
      id: 25,
      Q25: "Are you looking for permanent, temporary, or intern/freelance employment?",
      A25: "",
      inputType: "select",
      options: ["Permanent", "Temporary", "Intern", "Freelance",
        "Custom..."],
      key: "Employment_Type",
      isUploadedQuestion:"Yes"
    },
    {
      id: 26,
      Q26: "Please select the currency.",
      A26: "",
      inputType: "select",
      options: ["EUR", "USD", "GBP", "Custom..."],
      key: "currency",
      isUploadedQuestion:"Yes"
    },
    {
      id: 27,
      Q27: "Please select the salary type.",
      A27: "",
      inputType: "select",
      options: ["Monthly", "Yearly",
        "Custom..."],
      key: "salary_type",
      isUploadedQuestion:"Yes" 
    },
  
    {
      id: 28,
      Q28: `please select the salary range.`,
      A28: "",
      inputType: "select",
      // options: salaryTypeSelectedAnswer && salaryTypeSelectedAnswer==="Monthly"?["0-2,500", "2,500-3,750","3,750-5,000","5,000-7,000","7,000-10,000","10,000+","Custom... (please specify)"]:["0-30,000","30,000-45,000","45,000-60,000","60,000-84,000","84,000-120,000","120,000+","Custom... (please specify)"],
      options: [
        "0-2,500",
        "2,500-3,750",
        "3,750-5,000",
        "5,000-7,000",
        "7,000-10,000",
        "10,000+",
        "Don't Want To Mention",
        "Custom...",
      ],
      key: "salary_range",
      isUploadedQuestion:"Yes"
    },
    {
      id: 29,
      Q29: `please select the salary range.`,
      A29: "",
      inputType: "select",
      // options: salaryTypeSelectedAnswer && salaryTypeSelectedAnswer==="Monthly"?["0-2,500", "2,500-3,750","3,750-5,000","5,000-7,000","7,000-10,000","10,000+","Custom... (please specify)"]:["0-30,000","30,000-45,000","45,000-60,000","60,000-84,000","84,000-120,000","120,000+","Custom... (please specify)"],
      options: [
        "0-30,000",
        "30,000-45,000",
        "45,000-60,000",
        "60,000-84,000",
        "84,000-120,000",
        "120,000+",
        "Don't Want To Mention",
        "Custom...",
      ],
      key: "salary_range",
      isUploadedQuestion:"Yes"
    },
    {
      id: 30,
      Q30: "Please specify any additional benefits offered.",
      A30: "",
      inputType: "text",
      key: "additional_benefits",
      isUploadedQuestion:"Yes"
    },
    {
      id: 31,
      Q31: "What are the expected hours of work for this position?",
      A31: "",
      inputType: "text",
      key: "working_hours",
      isUploadedQuestion:"Yes"
    },
    {
      id: 32,
      Q32: "When is the application deadline?",
      A32: "",
      options:[
        "No deadline", "Select Date"
      ],
      inputType: "date",
      key: "app_deadline",
      isUploadedQuestion:"Yes"
    },
    {
      id: 33,
      Q33: "How should candidates apply?",
      A33: "",
      inputType: "text",
      key: "application_platform",
      isUploadedQuestion:"Yes"
    },
    {
      id: 34,
      Q34: `Would you like to proceed with generating your job vacancy text now, or would you like to answer a
            few additional questions to further refine your text using value-driven elements, storytelling, and
            neuromarketing techniques?`,
      A34: "",
      options: ["Proceed Now", "Enhance My Text"],
      inputType: "select",
      key: "special_questions",
    },
    {
      id: 35,
      Q35: "What makes this job opportunity unique or valuable to potential candidates?",
      A35: "",
      inputType: "text",
      // options: [
      //   "Innovative work environment",
      //   "Career growth opportunities",
      //   "Competitive salary",
      //   "Great team culture",
      //   "Custom...",
      // ],
      key: "value_proposition",
      isUploadedQuestion:"Yes"
    },
    {
      id: 36,
      Q36: "What feelings do you want candidates to associate with this job opportunity?",
      A36: "",
      inputType: "select",
      options: ["Excitement", "Fulfillment", "Challenge", "Growth", "Stability",
        "Custom..."],
      key: "emotional_connection",
      isUploadedQuestion:"Yes"
    },
    {
      id: 37,
      Q37: "What specific benefits or perks would appeal most to potential candidates?",
      A37: "",
      inputType: "select",
      options: [
        "Flexible working hours",
        "Health benefits",
        "Paid time off",
        "Professional development",
        "Custom..."
      ],
      key: "key_selling_points",
      isUploadedQuestion:"Yes"
    },
    {
      id: 38,
      Q38: "How can we make the job vacancy text more engaging and memorable?",
      A38: "",
      inputType: "select",
      options: [
        "Pose a question for candidates to ponder",
        "Share a brief success story from a current employee",
        "Highlight an interesting project or initiative the successful candidate will be involved in",
        "Custom..."
      ],
      key: "engagement_strategy",
      isUploadedQuestion:"Yes"
    },
    {
      id: 39,
      Q39: "What tone or voice would best reflect the company culture and appeal to potential candidates?",
      A39: "",
      inputType: "select",
      options: [
        "Achievement",
        "Collaboration",
        "Opportunity",
        "Impact",
        "Growth",
        "Extracted tone of voice from company website",
        "Custom..."
      ],
      key: "Tone_of_Voice",
      isUploadedQuestion:"Yes"
    },

    {
      id: 40,
      Q40: `Does your company have a slogan? If so, please enter it below. If not, feel
free to skip this question.`,
      A40: "",
      inputType: "text",
      // options: [
      //   "Achievement",
      //   "Collaboration",
      //   "Opportunity",
      //   "Impact",
      //   "Growth",
      //   "Custom..."
      // ],
      key: "slogan",
      isUploadedQuestion:"Yes"
    },
    // {
    //   id: 40,
    //   Q40: `Does your company have a slogan? If so, please enter it below. If not, feel
    //         free to skip this question.`,
    //   A40: "",
    //   inputType: "text",
    //   // options: [
    //   //   "Achievement",
    //   //   "Collaboration",
    //   //   "Opportunity",
    //   //   "Impact",
    //   //   "Growth",  
    //   //   "Custom..."
    //   // ],
    //   key: "slogan",
    //   isUploadedQuestion:"Yes"
    // },
    {
      id: 41,
      Q41: "What makes our company great?",
      A41: "",
      inputType: "select",
      options: [
        "Our commitment to innovation and creativity.",
        "Our inclusive and supportive company culture.",
        "Our dedication to continuous learning and growth.",
        "Our focus on making a positive impact on society and the environment.",
        "Custom...",
      ],
      key: "what_makes_the_company_great",
      isUploadedQuestion:"Yes"
    },
    {
      id: 42,
      Q42: "What current trends or challenges do you see impacting this job opportunity? This could include industry shifts, societal changes, or global events. Example of Answer: With the rise of remote work and increasing focus on sustainability, this job opportunity offers a blendof flexibility and purpose-driven work aligned with evolving industry and societal needs. How can we tailor the job vacancy text to reflect these trends and emphasize the unique value proposition?",
      A42: "",
      inputType: "text",
      key: "Context",
      isUploadedQuestion:"Yes"
    },
    {
      id: 43,
      Q43: "We have reached to the end of the questions",
      A43: "",
      inputType: "text",
      key: "end",
    },
  ];

//Hashmap containing predefined competency values
export const competencyList = new Map([
    ['Leadership', "Provides others with clear instructions and guidelines. Adapts leadership style to the situation or individual."],
    ['Communication', "Keeps people actively informed and communicates relevant information in a clear and concise manner. Listens well and considers the opinions and points of view of others."],
    ['Problem-solving', "Dissects and analyses information effectively. Recognises the causes of problems and proposes realistic and practical solutions to address them."],
    ['Teamwork', "Maps the potential risks of a particular approach. Shows willingness to take responsible risks to achieve goals."],
    ['Innovation', "Comes up with new and original ideas, approaches, and solutions to problems. Looks beyond conventional approaches and established methods."]
]);
 
//function to check whether predefined answer matches the options in the question
export const IsOptionMatches=(currentQuestion,pdfAnswer)=>{
    let currentQuestionsAnswer;
    currentQuestionsAnswer=findMatchingAnswer(currentQuestion?.key,pdfAnswer);
    console?.log('currentQuestionsAnswer800',currentQuestionsAnswer);
    // if(currentQuestion?.key==="salary_range")
    // currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-6}`];
    //  else
    // currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-5}`];
    // // const currentQuestionsAnswer=sortedPdfAnswers[`A${currentQuestion?.id-6}`];
    // console.log("currentQuestionsAnswer25",currentQuestionsAnswer)
    const result=currentQuestion?.options?.filter((option)=>currentQuestionsAnswer?.includes(option));
    console.log('result12',result);
    return {IsOptionMatches:result.length>0,matchingOptions:result};
  }

  //look for answer to be selected in pdf
  export const findMatchingAnswer=(currentQuestionKey,pdfAnswers)=>{
    console?.log("currentQuestionKey90",pdfAnswers[currentQuestionKey])
      return pdfAnswers[currentQuestionKey]||"";
  }

  export const names = [
    {questionKey:"Top_Competencies",optionName:"Select Competencies",optionPrefilledValue:``},
    {questionKey:"Top_Competencies",optionName:"Managing others",optionPrefilledValue:`Provides others with clear instructions and guidelines. Adapts leadership style to the situation or individual.`},
    {questionKey:"Top_Competencies",optionName:"Influencing others",optionPrefilledValue:`Uses direct and indirect strategies to influence the behavior of others. Uses persuasive and cogent arguments to convince others.`},
    {questionKey:"Top_Competencies",optionName:"Decisiveness",optionPrefilledValue:"Makes decisions in a timely manner and with the necessary conviction, even in uncertain situations and with incomplete information. Is willing to make unpopular decisions and implement them."},
    {questionKey:"Top_Competencies",optionName:"Reliability",optionPrefilledValue:`Always achieves agreed goals and meets expectations. Fulfills obligations and takes responsibility for own performance.`},
{questionKey:"Top_Competencies",optionName:"Commercial insight",optionPrefilledValue:`Demonstrates a practical understanding of and is focused on commercial and business issues that may arise in
both the short and long term.
`},
{questionKey:"Top_Competencies",optionName:"Communication",optionPrefilledValue:`Keeps people actively informed and communicates relevant information in a clear and concise manner.
Listens well and considers the opinions and points of view of others.
`},
{questionKey:"Top_Competencies",optionName:"Conceptual thinking",optionPrefilledValue:`Understands the relationships between different types of data and sees the broader context. Uses existing
theories and concepts to understand practical situations. Creates new concepts to explain complex
information.`},
{questionKey:"Top_Competencies",optionName:"Conflict management",optionPrefilledValue:`Identifies differences of opinion between others and deals with them effectively. Resolves conflicts through
open discussion and a diplomatic approach.`},
{questionKey:"Top_Competencies",optionName:"Delegate effectively",optionPrefilledValue:`Delegates to others work appropriate to their knowledge, skills and experience. Provides others with the necessary
resources and authority to make decisions and take responsibility.`},
{questionKey:"Top_Competencies",optionName:"Energy",optionPrefilledValue:`Demonstrates an enthusiastic and committed approach and is able to maintain a high work pace for a long period of time.`},
{questionKey:"Top_Competencies",optionName:"Flexibility",optionPrefilledValue:`Adapts easily to new situations and ways of working. Is open to changes and is prepared to implement them
quickly.`},
{questionKey:"Top_Competencies",optionName:"Impact",optionPrefilledValue:`Makes a positive first impression, is immediately 'present' and comes across as credible. Knows how to maintain
this credibility even after a long period of time.`},
{questionKey:"Top_Competencies",optionName:"Search for information",optionPrefilledValue:`Searches for and collects all information necessary to achieve objectives, using various sources and
approaches.`},
{questionKey:"Top_Competencies",optionName:"Initiative",optionPrefilledValue:`Has a proactive attitude and seizes opportunities without having to be prompted to do so by others.`},
{questionKey:"Top_Competencies",optionName:"Innovation and creativity",optionPrefilledValue:`Comes up with new and original ideas, approaches and solutions to problems. Looks beyond conventional
approaches and established methods.`},
{questionKey:"Top_Competencies",optionName:"Integrity",optionPrefilledValue:`Maintains a high standard of honesty and reliability. Considers the ethical aspects of matters and decisions but remains pragmatic. Uses the right moments to discuss difficult or sensitive subjects`},
{questionKey:"Top_Competencies",optionName:"Interpersonal sensitivity",optionPrefilledValue:`Uses a thoughtful and tactful approach and recognizes the feelings of others.
`},
{questionKey:"Top_Competencies",optionName:"Customer focus",optionPrefilledValue:`Understands and maps customer needs. Focuses on meeting these needs. Strives for customer satisfaction and a
good relationship with the customer.`},
  {questionKey:"Top_Competencies",optionName:"Quality orientation",optionPrefilledValue:`Maintains high quality standards in work and encourages others to meet these standards as well. Continuously evaluates work to achieve excellent results.`},
  {questionKey:"Top_Competencies",optionName:"Market and competitive awareness",optionPrefilledValue:`Shows awareness of the current situation and new developments in the market and at competing companies. Considers how this will affect the organization.
`},
{questionKey:"Top_Competencies",optionName:"Oral communication",optionPrefilledValue:`Communicates information orally in a clear and confident manner. Adapts language to audience and checks for understanding.`},
{questionKey:"Top_Competencies",optionName:"Motivating others",optionPrefilledValue:`Enthuses and encourages others to successfully achieve goals`},
{questionKey:"Top_Competencies",optionName:"Multicultural awareness",optionPrefilledValue:`Enthuses and encourages others to successfully achieve goals`},
{questionKey:"Top_Competencies",optionName:"Networks",optionPrefilledValue:`Develops and maintains personal networks, both inside and outside the organization. Uses these to gain advantage for both
himself and the organization.`},
{questionKey:"Top_Competencies",optionName:"Negotiate",optionPrefilledValue:`Recognizes the objectives of different parties. Negotiates mutually acceptable solutions by making compromises and creating winwin situations.`},
{questionKey:"Top_Competencies",optionName:"Entrepreneurship",optionPrefilledValue:`Actively seeks out new market opportunities and seizes opportunities. Proposes new services that meet current and future
market needs.`},
{questionKey:"Top_Competencies",optionName:"Developing and coaching others",optionPrefilledValue:`Recognizes and develops talent in others. Supports others in a positive way so that they can get the best out of themselves.`},
{questionKey:"Top_Competencies",optionName:"Eye for detail",optionPrefilledValue:`Pays great attention to detail, thus guaranteeing the quality of work delivered.`},
{questionKey:"Top_Competencies",optionName:"Judgment and decision making",optionPrefilledValue:`Makes the right judgment when decisions need to be made by considering all available information and alternatives. Substantiates
decisions with clear and well-considered arguments.`},
{questionKey:"Top_Competencies",optionName:"Openness to ideas",optionPrefilledValue:`Is open to and curious about new ideas and approaches. Develops ideas further and considers how they can be incorporated into his/
her own framework.`},
{questionKey:"Top_Competencies",optionName:"Organizational commitment",optionPrefilledValue:`Aligns own behavior with the organization's objectives. Acts in the organization's interest.`},
{questionKey:"Top_Competencies",optionName:"Organizational awareness",optionPrefilledValue:`Has a sense and understanding of the structure, political relationships and objectives of the organization and the influence they
can have on situations and themes that arise.`},
{questionKey:"Top_Competencies",optionName:"Organizing and Prioritizing",optionPrefilledValue:`Organizes information and resources effectively. Prioritizes tasks based on urgency or importance.`},
{questionKey:"Top_Competencies",optionName:"Organizing changes",optionPrefilledValue:`Implements and monitors changes at work. Substantiates initiatives for change with clear arguments and is prepared to respond to
criticism in order to create support.`},
{questionKey:"Top_Competencies",optionName:"Plans",optionPrefilledValue:`Plans work to achieve set goals. Maps out the method of working and necessary steps in advance. Takes unforeseen events
into account and adjusts plans if necessary.`},
{questionKey:"Top_Competencies",optionName:"Problem solving skills",optionPrefilledValue:`Dissects and analyses information effectively. Recognises the causes of problems and proposes realistic and practical solutions
to address them.`},
{questionKey:"Top_Competencies",optionName:"Developing relationships",optionPrefilledValue:`Easily develops and maintains effective and positive professional relationships with others.`},
{questionKey:"Top_Competencies",optionName:"Results-oriented",optionPrefilledValue:`Focuses on achieving results and goals. Constantly strives to meet or exceed expectations.`},
{questionKey:"Top_Competencies",optionName:"Taking risks",optionPrefilledValue:`Maps the potential risks of a particular approach. Shows willingness to take responsible risks, take to achieve goals.`},
{questionKey:"Top_Competencies",optionName:"To collaborate",optionPrefilledValue:`Is cooperative within the team, shares knowledge and information. Strives for harmony within the team and makes efforts for the
benefit of the team.`},
{questionKey:"Top_Competencies",optionName:"Encouraging collaboration",optionPrefilledValue:`Encourages cooperation by bringing individuals and groups together to achieve goals and objectives.`},
{questionKey:"Top_Competencies",optionName:"Written communication",optionPrefilledValue:`Communicates written information clearly, concisely, and unambiguously. Structures written material in a way that holds the
reader's attention.`},
{questionKey:"Top_Competencies",optionName:"Strategic thinking",optionPrefilledValue:`Formulates strategies and approaches to clarify and realize the organization's vision and objectives.`},
{questionKey:"Top_Competencies",optionName:"Stress tolerance",optionPrefilledValue:`Maintains a calm, controlled and positive attitude under pressure and remains focused on the task at hand`},
{questionKey:"Top_Competencies",optionName:"Systematic thinking",optionPrefilledValue:`Understands how various elements within a process or system are interrelated. Considers the consequences that changing one
element can have for other parts of the process`},
{questionKey:"Top_Competencies",optionName:"Technical and professional knowledge",optionPrefilledValue:`The level of technical and professional knowledge and skills is appropriate to his/her role. Relies on this knowledge and skills and shares it with others.`},
{questionKey:"Top_Competencies",optionName:"Perseverance",optionPrefilledValue:`Shows resilience in the face of adversity. Is persistent and continues to strive to achieve goals despite all obstacles.`},
{questionKey:"Top_Competencies",optionName:"Vision",optionPrefilledValue:`Identifies and develops a clear vision for the team or organization from a range of possibilities.`},
{questionKey:"Top_Competencies",optionName:"Communicating a vision",optionPrefilledValue:`Conveys the vision of the organization or team in a positive and enthusiastic manner.`},
{questionKey:"Top_Competencies",optionName:"Self-awareness",optionPrefilledValue:`Recognizes own emotions and knows the influence of these on own behavior. Knows own possibilities and limitations.
Keeps an eye on the effect on others.`},
{questionKey:"Top_Competencies",optionName:"Self-learning ability",optionPrefilledValue:`Actively seeks opportunities to learn and develop himself. Is willing to learn from both positive and negative experiences`},
{questionKey:"Top_Competencies",optionName:"Independence",optionPrefilledValue:`Is independent in performing tasks. Asks others for advice or assistance only when necessary for effective completion of the
project.`},
{questionKey:"Top_Competencies",optionName:"Self-confidence",optionPrefilledValue:`Believes in one's own worth and capabilities, even in uncertain or
new situations.`},
    {questionKey:"Top_Competencies",optionName:"Custom...",optionPrefilledValue:""},
];

export const selectedOptionsValue=(selectedOption)=>{
   const matchedValue=names.filter(ele=>ele.optionName===selectedOption);
   return matchedValue[0]?.optionPrefilledValue;
}
    // 'Oliver Hansen',
    // 'Van Henry',
    // 'April Tucker',
    // 'Ralph Hubbard',
    // 'Omar Alexander',
    // 'Carlos Abbott',
    // 'Miriam Wagner',
    // 'Bradley Wilkerson',
    // 'Virginia Andrews',
    // 'Kelly Snyder',
  

