import { Box, Button, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router';

const SubscriptionCard = ({item, hideBtn}) => {
    const navigate = useNavigate();
  return (
    <Box className={`SubscriptionPlan-card ${item?.mostPopular && 'active'}`}>
                <Button className='most-popular-btn'>
                  MOST POPULAR
                </Button>
                <Typography className=' m-0 cost'><span className='text-orange p-0 fs-36 fw-700'>${item.price}&nbsp;</span><span className='p-0 fs-17 fw-700' style={{color:"#848199"}}>/{item.validityType==="months"?"month":"year"}</span></Typography>
                <Typography variant='h4' className='title'>{item.name}</Typography>
                <Typography variant='h4' className='sub-title'>{item.desc}</Typography>
                <List>
                  {item.points.map((point, index) => {
                    return(
                    <ListItem key={index}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z" fill="#F3E8DF"/>
                    <path d="M15.7722 6.83362L10.068 14.5745C9.93197 14.7549 9.72912 14.8732 9.50503 14.9027C9.28094 14.9321 9.05441 14.8703 8.87634 14.7311L4.80301 11.4745C4.44356 11.1868 4.38536 10.6622 4.67301 10.3028C4.96066 9.94334 5.48523 9.88514 5.84468 10.1728L9.24134 12.8903L14.4305 5.84778C14.6007 5.59244 14.8974 5.45127 15.2029 5.48032C15.5083 5.50936 15.7731 5.70393 15.8921 5.98676C16.0111 6.2696 15.965 6.59494 15.7722 6.83362Z" fill="#F89B53"/>
                    </svg>
                    {point}</ListItem>
                    )
                  })}
                </List>
                {!hideBtn && <Button onClick={() => navigate(`/payment/${item._id}`)} className='primary-btn w-100'>Choose plan</Button>}
                
              </Box>
  )
}

export default SubscriptionCard
