import api from "./api";
import ActionName from "./actionName";
import constants from "./constants";
import EndPoint from "./endpoints";
const Utils = {
  api,
  EndPoint,
  ActionName,
  constants,
};

export default Utils;
