import React, { useEffect, useState } from "react";
import { useStripe, useElements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function ApplePayCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const subscription = useSelector(
    (state) => state?.subscriptions?.subscription
  );

  useEffect(() => {
    if (!stripe || !subscription) {
      return;
    }

    // Create a PaymentRequest for Apple Pay
    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Subscription Payment",
        amount: subscription?.price * 100, // Stripe expects the amount in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
      supportedPaymentMethods: [
        {
          supportedMethods: 'apple_pay',
        },
      ],
    });

    // Check if Apple Pay can be used
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setCanMakePayment(true);
      }
    });
  }, [stripe, subscription]);

  return (
    <Box>
      <Typography variant="h3" className="fs-22 fw-600">
        Apple Pay
      </Typography>
      {canMakePayment ? (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: "default",
                theme: "light",
                height: "64px",
                text: "pay", // Customize the text
              },
            },
          }}
        />
      ) : (
        <Typography>Apple Pay is not available on this device/browser.</Typography>
      )}
    </Box>
  );
}
