import {
    PaymentElement,
    LinkAuthenticationElement
  } from '@stripe/react-stripe-js'
  import {useState} from 'react'
  import {useStripe, useElements} from '@stripe/react-stripe-js';
import { Box, Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

  
  export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const subscription = useSelector(
      (state) => state?.subscriptions?.subscription
    );
    console.log('subscription',subscription?.price);
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/confirm`,
        },
      });
      console.log('paymentIntent',paymentIntent,elements)
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
  
      setIsLoading(false);
    }
  
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement id="link-authentication-element"
          // Access the email value like so:
          // onChange={(event) => {
          //  setEmail(event.value.email);
          // }}
          //
          // Prefill the email field like so:
          // options={{defaultValues: {email: 'foo@bar.com'}}}
          />
        <PaymentElement id="payment-element" />
        {/* <Button disabled={isLoading || !stripe || !elements} > */}
         
            {isLoading ? <div className="spinner" id="spinner"></div> :  
                        <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ marginTop: "74px" }}
                      >
                        <Box className="flex-wrap text-end justify-content-end ml-auto w-100 gap-10">
                          <Button
                            className="primary-btn"
                            sx={{ width: "144px" }}
                            type="reset"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="primary-btn-outline"
                            sx={{ width: "144px" }}
                            disabled={isLoading || !stripe || !elements} 
                            type="submit"
                          >
                          {
                            `Pay $${subscription?.price}` 
                          }
                            
                          </Button>
                        </Box>
                      </Grid>}
          
        {/* </Button> */}
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </form>
    )
  }