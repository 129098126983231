import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
const ServiceTerms = () => {
  return (
    <Box>
        <Header></Header>
        <Box>
            <Grid container className='px-5'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h1' className='fs-37 fw-700 text-center'>Terms of Service </Typography>
                    <Typography variant='h4' className='fs-15 fw-400 text-center px-5 mt-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </Typography>

                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Amet minim mdsdso</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium f</Typography>

                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magum.</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Integer id augue iaculis, iaculis orci ut, blandit quam. Donec in elit auctor, finibus quam in, phar</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Equat duis enim velit</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Vestibulum eu quam nec neque pellentesque efficitur id eget nisl. Proin porta est convallis lacus bl</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula </Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Mollit. Exercitastion</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Ua dolor do amet sint</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean posuere massa pellentesque sagittis ultricies. Curabitur tristique magna at enim varius, quis</Typography>

                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Veniam consequsat</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Nibh tellus molestie nunc non blandit massa enim. Facilisis magna etiam tempor orci eu lobortis elem</Typography>
                
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llamco est sit alissq</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ex enim, euismod non consequat a, eg</Typography>    
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Llit non deserunt u</Typography>
                    <Typography variant='h3' className='fs-15 fw-400 mb-30'>Aenean sed dictum lacus. Aenean id felis metus. Maecenas mollis sem vitae elit fringilla, quis dictu</Typography>
                    
                    <Typography variant='h2' className='fs-22 fw-800 mb-20'>Velit officia conddss</Typography>

                </Grid>
            </Grid>
        </Box>
        <Footer></Footer>

    </Box>
  )
}

export default ServiceTerms
