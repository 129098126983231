import {
    Box,
    Button,
    dialogClasses,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    Menu,
    MenuItem,
    Select,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import Header from "../../components/header";
  import Footer from "../../components/footer";
  import "../job/job.scss";
  import editsvg from "../../assets/img/svg/edit.svg";
  import copysvg from "../../assets/img/svg/copy.svg";
  import downloadsvg from "../../assets/img/svg/download.svg";
  import sharesvg from "../../assets/img/svg/share.svg";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import JobTitle from "../../assets/img/svg/JobTitle.svg";
  import ToneofVoice from "../../assets/img/svg/ToneofVoice.svg";
  import Neuromarketing from "../../assets/img/svg/Neuromarketing.svg";
  import ElementsofValue from "../../assets/img/svg/ElementsofValue.svg";
  import addsvg from "../../assets/img/svg/add.svg";
  import sparksvg from "../../assets/img/svg/spark.svg";
  import Loader from "../../components/Loader";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import Otherpopup from "./otherpopup";
  
  import TextareaAutosize from "@mui/material/TextareaAutosize";
  import { toast } from "react-toastify";
  import { Link, useNavigate } from "react-router-dom";
  import { architypes } from "./helper";

const TestAchitype = () => {
    return (
        <Box>
          
            <>
              <Header />
              <Box className="py-2 mt-10">
                <Grid container spacing={2} className="px-5">
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
                    <Typography variant="h2" className="fs-32 fw-600 px-4">
                      Job Vacancy Text
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    className=" py-2 flex-wrap justify-content-end"
                  >
                    <Box className="gap-10 flex-wrap">
                      <Box className="flex-wrap justify-content-end">
                        <img
                          className={`my-auto edit-mode-disabled`}
                          width={27}
                          height={27}
                          src={editsvg}
                        //   onClick={handleEditMode}
                          style={{ cursor: "pointer", borderRadius: "5px" }}
                        />
                        <Divider
                          className="mx-2"
                          orientation="vertical"
                          flexItem
                          sx={{ border: "1px solid black" }}
                        />
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                        //   onClick={handleCopyContent}
                          style={{ cursor: "pointer" }}
                        />
                        <Divider
                          className="mx-2"
                          orientation="vertical"
                          flexItem
                          sx={{ border: "1px solid black" }}
                        />
                     
    
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={downloadsvg}
                          style={{ cursor: "pointer" }}
                          alt="Download PDF"
                          title="Download PDF"
                        />
    
                        <Divider
                          className="mx-2"
                          orientation="vertical"
                          flexItem
                          sx={{ border: "1px solid black" }}
                        />
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={sharesvg}
                          style={{ cursor: "pointer" }}
                        //   onClick={handleShareEmail}
                        />
                        <Divider
                          className="mx-2"
                          orientation="vertical"
                          flexItem
                          sx={{ border: "1px solid black" }}
                        />
                        <Box className="flex-wrap gap-10">
                          <div>
                            <Button
                              className="primary-btn-outline gap-10"
                              sx={{ minWidth: "fit-content" }}
                              id="basic-button"
                              aria-controls={"basic-menu"}
                              aria-haspopup="true"
                              aria-expanded={"true"}
                            //   onClick={handleClick}
                            >
                              <img src={sparksvg} />
                              Tone of Voice <KeyboardArrowDownIcon />
                            </Button>
    
                            <Menu
                              className="toneofvoice"
                              id="basic-menu"
                            //   anchorEl={anchorEl}
                            //   open={open}
                            //   onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                // onClick={() => handleToneOfVoice("friendlyTone")}
                              >
                                Friendly Tone
                              </MenuItem>
                              <MenuItem
                                // onClick={() => handleToneOfVoice("innovativeTone")}
                              >
                                Innovative Tone
                              </MenuItem>
                              <MenuItem
                                // onClick={() =>
                                //   handleToneOfVoice("professionalTone")
                                // }
                              >
                                Professional Tone
                              </MenuItem>
                              <MenuItem
                                // onClick={() =>
                                //   handleToneOfVoice("inspirationalTone")
                                // }
                              >
                                Inspirational Tone
                              </MenuItem>
                              <MenuItem
                                // onClick={() => handleToneOfVoice("informativeTone")}
                              >
                                Informative Tone
                              </MenuItem>
                              <MenuItem 
                            //   onClick={() => handleChange("others")}
                              >
                                Others
                              </MenuItem>
                            </Menu>
                          </div>
    
                          <Link
                            // to={`/job/upload/${vacancyId}`}
                            // state={{ editedData: editedVacancyText }}
                          >
                            {" "}
                            <Button
                              className="primary-btn-outline gap-10"
                              sx={{ minWidth: "fit-content" }}
                            >
                              <img src={addsvg} />
                              Generate the Social Media Template{" "}
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
                    <Box className="vacancy-content">
                  
                     {
                        architypes?.map((ele,index)=>(
                            <div>
                            <Typography
                            className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                            variant="h3"
                          >
                            {ele?.section}
                          <span>
                          <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                        //   onClick={handleCopyAboutTheFunctionContent} 
                          style={{ cursor: "pointer" }}
                        />
                          </span>
                           
                          </Typography>
                          <div>
                            {
                                ele?.paragraphs?.length>0 &&
                                <TextareaAutosize
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          aria-label="minimum height"
                          minRows={1}
                          placeholder="Enter Job Criteria"
                          style={{ width: "100%" }}
                          value={ele?.paragraphs.join('\n')}
                        //   onChange={(e) =>
                        //     handleCriteriaEditData(
                        //       criteriaKey,
                        //       e.target.value.split("\n")
                        //     )
                        //   } 
                        />
                            }
                          </div>
                          <div className="fs-18  fw-500 mt-20 d-flex justify-content-between">
                            {
                                ele?.bulletpoints?.length>0 &&(
                                   <ul>
                                    {
                                        ele?.bulletpoints?.map((data,index)=>(
                                          <li key={index}>{data}</li>
                                        ))
                                    }
                                   </ul>    
                                )
                            }
                          </div>
                          </div>
                        ))
                      }
                  

    
                   
                    </Box>
                    <Box className="flex-wrap justify-content-between my-30">
                      <Button
                        className="primary-btn "
                        // onClick={handleBeautifyTemplate}
                      >
                        Beautify Template{" "}
                      </Button>
                      <Box>
                        <Button
                        //   onClick={() => {
                        //     handleOpenPopup();
                        //   }}
                          className="primary-btn-outline mr-10"
                          sx={{ minWidth: "144px" }}
                        >
                          Discard
                        </Button>
                        <Button
                        //   disabled={!isEditEnabled}
                        //   onClick={handleSubmit}
                          className="primary-btn"
                          sx={{ minWidth: "144px" }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <Box
                      sx={{
                        background: "#FFF3E9",
                        height: "100%",
                        padding: "28px 24px ",
                      }}
                    //   ref={contentRef2}
                    >
                      
                     
                    
                      <Box>
                       
                        <Box
                          className="bg-white mt-10 mb-10"
                          sx={{ padding: "12px 14px" }}
                        >
                          <Typography className="fs-16 fw-500 color-primary">
                            {/* {vacancyTextData?.Tone_of_voice}{" "} */}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Footer />
            </>
        
        </Box>
      );
}

export default TestAchitype
