import Utils from "../../utils/reduxUtils";

const {
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_BY_ID,
    PAYMENT_SUCCEDED,
    GENERATE_PAYMENT_INTENT
} = Utils.ActionName;

const initialState = {};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS:
            return { ...state, subscriptions:action.payload };
        case GET_SUBSCRIPTIONS_BY_ID:
            return { ...state, subscription:action.payload.data.subscription };
        case GENERATE_PAYMENT_INTENT:
            return { ...state, paymentIntent:action.payload };
        case PAYMENT_SUCCEDED:
            return { ...initialState};
        default:
            return state;
    }
};