import { Box, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './loader.scss'
const messages = [
  "5x Higher Conversion with Neuromarketing",
  "Storytelling activates brain areas that create lasting memories",
  "The right job title connects your vacancy to the ideal candidate",
  "Employees choose companies for emotional bonds",
  "Our system identifies and uses the most effective keywords"
];
const Loader = () => {
    const [progress, setProgress] = React.useState(0);

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 3000); // Change every 3 seconds
  
      return () => clearInterval(interval);
    }, []);
  return (
        <Box className='loader mx-auto'>
            <Box className='content'>
                <Typography variant='h4' className='fs-38 fw-600'>Getting</Typography>
                <Typography variant='h4' className='fs-58 fw-600'>Generated</Typography>
            </Box>
            <Box sx={{ display: 'block', alignItems: 'center',width: '90%',maxWidth:'857px' }} className='mx-auto mt-50'>
                    <Box sx={{ width: '100%',maxWidth:'857px' }}>
                        <Typography variant="body2" className='text-center text-orange fs-27 fw-600' >{`${Math.round(
                            progress,
                        )}%`}</Typography>
                    </Box>
                    <Box sx={{ width: '100%',maxWidth:'857px',marginTop:'20px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
            </Box>
            {/* <Box>
                <Typography variant='h4' className='fs-37 fw-600 text-center mt-50 mx-auto' sx={{maxWidth:"920px"}}> 5x Higher Conversion with Neuromarketing</Typography>
                <Typography variant='h4' className='fs-37 fw-600 text-center mt-50 mx-auto' sx={{maxWidth:"920px"}}> Storytelling activates brain areas that create lasting memories</Typography>
                <Typography variant='h4' className='fs-37 fw-600 text-center mt-50 mx-auto' sx={{maxWidth:"920px"}}> The right job title connects your vacancy to the ideal candidate</Typography>
                <Typography variant='h4' className='fs-37 fw-600 text-center mt-50 mx-auto' sx={{maxWidth:"920px"}}> Employees choose companies for emotional bonds</Typography>
                <Typography variant='h4' className='fs-37 fw-600 text-center mt-50 mx-auto' sx={{maxWidth:"920px"}}> Our system identifies and uses the most effective keywords</Typography>
            </Box> */}
              <Box sx={{
      position: 'relative',
      height: '140px', // Adjust as needed
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {messages.map((message, index) => (
        <Typography
          key={index}
          variant="h4"
          className='fs-37 fw-600 text-center mt-40'
          sx={{
            maxWidth: "920px",
            position: 'absolute',
            transform: `translateY(${(index - currentMessageIndex) * 100}%)`,
            transition: 'transform 1s ease-in-out',
            opacity: index === currentMessageIndex ? 1 : 0,
            transition: 'opacity 1s ease-in-out, transform 1s ease-in-out'
          }}
        >
          {message}
        </Typography>
      ))}
    </Box>
        </Box>
  )
}

export default Loader;
