export const architypes = [
    {
        
        section: "Job Title",
        paragraphs: [
          "Software Developer",
        ],
        bulletpoints: [
         
        ],
        },
    {
        
    section: "StoryTelling",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
     
    ],
    },
  {

    section: "Who are we?",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
      "Student-driven learning in a modern research environment.",
      "Strong connections with regional and international companies.",
      "Encouraging cross-disciplinary and innovative learning.",
      "Fostering a multicultural and collaborative educational community.",
      "Creating practical knowledge applicable to the real-world workforce.",
    ],
  },
  {
    section: "Job requirements",
    paragraphs: [
      "We are looking for a candidate with advanced academic qualifications in the field of Cyber Security. Ideally, you will hold a master’s degree or PhD, allowing you to contribute to our cutting-edge programs with a solid foundation in this critical domain.",
      "In addition to your academic background, you should possess a strong professional network in Cyber Security, both on a national and international scale. Fluency in English, both spoken and written, is essential for this role, as you will be required to communicate effectively in a global environment.",
    ],
    bulletpoints: [
      "You have a master’s degree or PhD in the field of Cyber Security.",
      "You have a relevant (inter)national network in the world of Cyber Security.",
      "You have an excellent command of the English language both orally and in writing.",
    ],
  },
  {
    section: "Acquisition",
    paragraphs: [
      "We are consciously and confidently working together with Effectus -HR to fill this vacancy. That is why Effectus -HR has been granted exclusivity for this vacancy. Given their 93% fulfillment rate, we are convinced that they will also find a more than suitable candidate for this vacancy. Acquisition in response to this vacancy is therefore neither necessary nor desirable.",
    ],
    bulletpoints: [
      
    ],
  },
];
